import React from 'react';
import whatsapp from '../images/whatsapp.svg';
import '../App.css'; // Import the CSS file

const Whatsapp = () => {
  return (
    <div className="whatsapp-container">
      <a
        href="https://wa.me/917901712453"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-link"
      >
        <img
          src={whatsapp}
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </a>
    </div>
  );
};

export default Whatsapp;
